html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  display: flex;
  align-items: center;
  padding-bottom: 40px;

  background-color: #f5f5f5;
}
